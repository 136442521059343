.container {
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.slider {
  position: relative;
  width: 100%;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 8px;
}

.slider__track {
  background-color: #4f545c;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: #e71037;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: #dee2e6;
  font-size: 12px;
  margin-top: 20px;
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
  :first-child {
    background-color: blue;
  }
}
.first-thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
}

.thumb--zindex-3 {
  z-index: 3;
}

.thumb--zindex-4 {
  z-index: 4;
}

.thumb--zindex-5 {
  z-index: 5;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 24px;
  width: 10px;
  margin-top: 8px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 24px;
  width: 10px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}
.first-thumb::-webkit-slider-thumb {
  background-color: #4f545c;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 1px 1px #4f545c;
  cursor: pointer;
  height: 24px;
  width: 10px;
  margin-top: 8px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.first-thumb::-moz-range-thumb {
  background-color: #4f545c;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 1px 1px #4f545c;
  cursor: pointer;
  height: 24px;
  width: 10px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

.wrapper {
  position: relative;
}

.wrapper .container {
  width: 98%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: -42px;
  margin: 0 1%;
}

.wrapper .container .marker {
  width: 2px;
  height: 24px;
  background: #4f545c;
  display: flex;
  justify-content: space-around;
  position: relative;
}

.wrapper .container .marker sup {
  position: absolute;
  top: -15px;
  font-size: 10px;
  font-weight: 700;
  color: #72767d;
}
